import React from 'react'
import { HiOutlineMenuAlt3 } from 'react-icons/hi'

const NavigationBar = ({ setToggle }) => {
  return (
    <div className='bg-black h-12 flex justify-end p-3'>
      <div onClick={() => setToggle(true)}>
        <HiOutlineMenuAlt3 className='text-white h-14 w-14 cursor-pointer hover:text-gray-400' />
      </div>
    </div>
  )
}

export default NavigationBar
