import React from 'react'
import ReactPlayer from 'react-player'

const videoLinksArray = [
  'https://www.youtube.com/watch?v=HcIrnomhX8o',
  'https://www.youtube.com/watch?v=v_laJ6MRgyg',
  'https://www.youtube.com/watch?v=6QIn4q2gU-k',
  'https://www.youtube.com/watch?v=G6JnxOxSxVs',
  'https://www.youtube.com/watch?v=2Sg5P9i6FmE',
  'https://www.youtube.com/watch?v=bB_3yp3QDmg',
  'https://www.youtube.com/watch?v=ZMjtxyzau5o',
]

const VideosView = () => {
  return (
    <React.Fragment>
      <div className='mt-10 flex items-center justify-center'>
        <h1 className='text-white font-semibold text-3xl mr-3 lg:text-5xl'>
          Videos
        </h1>
        <div className='border-t-2 w-24 border-white inline-block'></div>
      </div>
      <div className='px-10 flex flex-col items-center mt-10 space-y-4'>
        {videoLinksArray.map((link, i) => (
          <ReactPlayer url={link} controls />
        ))}
      </div>
      {/* <div className="w-full flex flex-col items-center">
        <iframe
        src='https://www.youtube.com/embed/E7wJTI-1dvQ'
        frameborder='0'
        allow='autoplay; encrypted-media'
        allowfullscreen
        title='video'
      />
      </div> */}
    </React.Fragment>
  )
}

export default VideosView
