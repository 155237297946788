import React from "react";
import { Link } from "@reach/router";

// import images
import birthday from "../assets/gallery_thumbnails/20th_bday.jpg";
import cityGirl from "../assets/gallery_thumbnails/city_girl.jpg";
import goldSound from "../assets/gallery_thumbnails/gold_sound.jpg";
import newYork from "../assets/gallery_thumbnails/new_york.jpg";
import popOfColour from "../assets/gallery_thumbnails/pop_of_colour.jpg";
import sobs from "../assets/gallery_thumbnails/sobs.jpg";

const picturesArray = [
  { image: birthday, title: "20th Birthday", link: "20th-birthday" },
  {
    image: cityGirl,
    title: "City Girl",
    link: "city-girl",
  },
  {
    image: goldSound,
    title: "Gold Sound",
    link: "gold-sound",
  },
  {
    image: newYork,
    title: "New York New York",
    link: "new-york",
  },
  {
    image: popOfColour,
    title: "Pop of Color",
    link: "pop-of-color",
  },
  { image: sobs, title: "S.O.B's", link: "sobs" },
];

const CardComponent = ({ image, title, link }) => {
  return (
    <Link to={`/gallery/${link}`}>
      <div className="w-full h-full relative hover:scale-110 duration-500 transform">
        <div className="galleryComponentImageGradient">
          <img className="" src={image} alt={title} />
        </div>
        <div className="absolute uppercase text-white filena text-xl p-2 galleryComponentGradient bottom-0 w-5/6">
          {title}
        </div>
      </div>
    </Link>
  );
};

const GalleryView = () => {
  return (
    <React.Fragment>
      <div className="mt-10 flex items-center justify-center">
        <h1 className="text-white font-semibold text-3xl mr-3 lg:text-5xl">
          Gallery
        </h1>
        <div className="border-t-2 w-24 border-white inline-block"></div>
      </div>
      <div className="mt-10 px-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
        {picturesArray.map((picture, index) => (
          <CardComponent
            image={picture.image}
            title={picture.title}
            link={picture.link}
            pho={picture.photographer}
            key={index}
          />
        ))}
      </div>
    </React.Fragment>
  );
};

export default GalleryView;
