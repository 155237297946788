import React from "react";
import { Icon } from "@iconify/react";
import spotifyFill from "@iconify-icons/akar-icons/spotify-fill";
import appleMusicFill from "@iconify-icons/cib/apple-music";
import albumArt from "../assets/noba_album_art.jpg";

const MusicView = () => {
  return (
    // <div className="text-white flex items-center justify-center h-screen">
    //   <div className="flex flex-row justify-start px-32 space-x-12 w-full border border-red-500">
    //     <div className="w-1/4 flex flex-col space-y-3">
    //       <img src={albumArt} alt="Noba album cover" className="h-72" />
    //       <h1 className="text-4xl font-bold">TELL ME</h1>
    //       <h2 className="font-medium">by Noba</h2>
    //       {/* Stream buttons */}
    //       <div className="flex space-x-4">
    //         <button className="border border-white px-8 py-5">
    //           Open on Spotify
    //         </button>
    //         <button className="border border-white px-8 py-5">
    //           Open on Apple Music
    //         </button>
    //       </div>
    //     </div>
    //     <div className="w-3/4 border border-white">space for song details</div>
    //   </div>
    // </div>
    <div className="text-white flex flex-col items-center justify-center h-screen space-y-8">
      <div className="mt-10 flex items-center justify-center mb-10">
        <h1 className="text-white font-semibold text-3xl mr-3 lg:text-5xl">
          Music
        </h1>
        <div className="border-t-2 w-24 border-white inline-block"></div>
      </div>
      <img src={albumArt} alt="Noba album cover" className="h-72" />
      <div className="flex flex-col items-center justify-center">
        <h1 className="text-4xl font-bold">TELL ME</h1>
        <h2 className="font-medium">by Noba</h2>
      </div>

      <div className="lg:w-1/4">
        <iframe
          title="Noba - Tell Me"
          src="https://open.spotify.com/embed/track/75LcWU5ownWtv30IwrPDbb"
          width="100%"
          height="80"
          frameBorder="0"
          allowtransparency="true"
          allow="encrypted-media"
        ></iframe>
      </div>
      {/* Stream buttons */}
      <div className="flex items-center justify-center space-x-4 mt-20 w-full">
        <a
          href="https://open.spotify.com/track/75LcWU5ownWtv30IwrPDbb?si=d3e57d3d685843d6"
          className="flex space-x-3 items-center border border-white px-10 py-5 hover:bg-white hover:text-black transition duration-500 ease-in-out"
          target="_blank"
          rel="noreferrer"
        >
          <Icon icon={spotifyFill} width="30" height="30" />
          <h1 className="hidden md:block ml-4">Open in Spotify</h1>
        </a>
        <a
          href="https://music.apple.com/za/album/tell-me/1566804520?i=1566804522"
          target="_blank"
          rel="noreferrer"
          className="flex border border-white px-10 py-5 hover:bg-white hover:text-black transition duration-500 ease-in-out"
        >
          <Icon icon={appleMusicFill} width="30" height="30" />
          <h1 className="hidden md:block ml-4">Open in Apple Music</h1>
        </a>
      </div>
    </div>
  );
};

export default MusicView;
