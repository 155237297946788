import React from 'react'

import { FaTwitter } from 'react-icons/fa'
import { FaInstagram } from 'react-icons/fa'
import { FaYoutube } from 'react-icons/fa'
import { FaFacebookF } from 'react-icons/fa'
import { MdEmail } from 'react-icons/md'

const Footer = () => {
  return (
    <div className='relative flex justify-center mt-20'>
      <div className='text-gray-300 absolute z-30 flex flex-col space-y-2 justify-center items-center pb-5'>
        <h1 className='playfairDisplay uppercase text-xl lg:text-5xl'>Noba</h1>
        <div className='flex space-x-4 '>
          <a href='mailto:noba.musicusa@gmail.com'>
            <MdEmail className='h-3 w-3 lg:h-6 lg:w-6 hover:text-gray-400' />
          </a>
          <a
            href='https://twitter.com/NOBA_MUSIC'
            target='_blank'
            rel='noreferrer'
          >
            <FaTwitter className='h-3 w-3 lg:h-6 lg:w-6 hover:text-gray-400' />
          </a>
          <a
            href='https://www.youtube.com/channel/UCm_iAC2HP4iiABkvKUb6JLA/videos'
            target='_blank'
            rel='noreferrer'
          >
            <FaYoutube className='h-3 w-3 lg:h-6 lg:w-6 hover:text-gray-400' />
          </a>
          <a
            href='https://www.instagram.com/noba_music/?hl=en '
            target='_blank'
            rel='noreferrer'
          >
            <FaInstagram className='h-3 w-3 lg:h-6 lg:w-6 hover:text-gray-400' />
          </a>
          <a
            href='https://www.facebook.com/NOBA-MUSIC-106314760774585'
            target='_blank'
            rel='noreferrer'
          >
            <FaFacebookF className='h-3 w-3 lg:h-6 lg:w-6 hover:text-gray-400' />
          </a>
        </div>
        <div className='font-light text-xs lg:text-sm'>
          <p>
            Designed and developed by{' '}
            <a
              className='hover:text-gray-600'
              rel='noreferrer'
              target='_blank'
              href='https://jordanmihlali.tech'
            >
              Mihlali Jordan
            </a>{' '}
          </p>
        </div>
      </div>
    </div>
  )
}

export default Footer
