import React, { useState, useEffect } from "react";
import { storage } from "../firebase";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { AiFillCamera } from "react-icons/ai";

// ************** Helper functions begin ************** //

const getLastSegmentOfUrl = (path) => {
  return path.substring(path.lastIndexOf("/") + 1);
};

const createAlbumTitleFromPathname = (array) => {
  const capitalisedArray = array.map((item) => capitaliseFirstLetter(item));

  return capitalisedArray.join(" ");
};

const capitaliseFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// const createImageUrlArray = (imageListRef) => {
//   let imageUrlArray = []

//   console.log(imageUrlArray[0])
//   imageListRef.listAll().then((res) => {
//     const tempArr = [...res.items]
//     console.log(tempArr[0])
//     tempArr.forEach((imageRef, i) =>
//       imageRef.getDownloadURL().then((url) => {
//         imageUrlArray.push(url)
//       })
//     )
//   })

//   return imageUrlArray
// }

// ************** Helper functions end ************** //

const photographers = [
  {
    name: "June Yun",
    shoot: "new-york",
  },
  {
    name: "Luke Bennet",
    shoot: "city-girl",
  },
  {
    name: "Alyriana Ivey",
    shoot: "pop-of-color",
  },
  {
    name: "Lauren Calton",
    shoot: "gold-sound",
  },
];

// DOM Component
const IndividualGalleryView = ({ location }) => {
  const [images, setImages] = useState([]);
  const pathname = location.pathname;
  const albumName = createAlbumTitleFromPathname(
    getLastSegmentOfUrl(pathname).split("-")
  );
  const storageRef = storage.ref();
  const imageListRef = storageRef.child(
    `images/${getLastSegmentOfUrl(pathname)}`
  );

  // console.log(createCarouselObjectArray(galleryArray))

  useEffect(() => {
    imageListRef
      .listAll()
      .then((res) => {
        res.items.forEach((imageRef, i) =>
          imageRef
            .getDownloadURL()
            .then((url) => {
              setImages((images) =>
                images.concat({ original: url, thumbnail: url })
              );
            })
            .catch((err) => console.error(err))
        );
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="my-10 flex items-center justify-center">
        <h1 className="text-white font-semibold text-3xl mr-3 lg:text-5xl">
          {albumName === "Sobs" ? "S.O.B's" : albumName}
        </h1>
        <div className="border-t-2 w-24 border-white inline-block"></div>
      </div>
      <div className="ml-4">
        {photographers.map((p) => {
          if (p.shoot === getLastSegmentOfUrl(pathname))
            return (
              <h1 className="text-white flex items-center lg:text-3xl">
                {" "}
                <AiFillCamera />
                {p.name}
              </h1>
            );
        })}
      </div>
      <div className="h-full w-full px-8">
        {images && (
          <React.Fragment>
            <ImageGallery useBrowserFullscreen="false" items={images} />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default IndividualGalleryView;
