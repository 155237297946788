const LandingPageView = () => {
  return (
    <div className='h-screen hero-image'>
      <h1 className='text-white text-5xl lg:text-8xl flex justify-center items-center mx-auto w-1/2 transform -translate-y-8 playfairDisplay uppercase'>
        Noba
      </h1>
    </div>
  )
}

export default LandingPageView
