import React, { useState } from 'react'
import { Router } from '@reach/router'

// Components
import LandingPageView from './views/LandingPageView'
import GalleryView from './views/GalleryView'
import AboutView from './views/AboutView'
import Footer from './Footer'
import NavigationBar from './NavigationBar'
import NavigationMenu from './NavigationMenu'
import IndividualGalleryView from './views/IndividualGalleryView'
import VideosView from './views/VideosView'
import MusicView from './views/MusicView'

const App = () => {
  const [toggle, setToggle] = useState(false)
  return (
    <React.Fragment>
      {toggle ? (
        <NavigationMenu setToggle={setToggle} />
      ) : (
        <NavigationBar setToggle={setToggle} />
      )}
      {!toggle && (
        <React.Fragment>
          <div className='bg-black'>
            <Router>
              <LandingPageView path='/' />
              <GalleryView path='/gallery' />
              <AboutView path='/about' />
              <IndividualGalleryView path='/gallery/:id' />
              <VideosView path='/videos' />
              <MusicView path="/music" />
            </Router>
          </div>
          <Footer />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default App
