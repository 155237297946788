import firebase from 'firebase/app'
import 'firebase/firebase-storage'

const firebaseConfig = {
  apiKey: 'AIzaSyAacIG3KGvDpGnJujnCRnnylgkGdJ_MKyQ',
  authDomain: 'nobamusicwebsite.firebaseapp.com',
  projectId: 'nobamusicwebsite',
  storageBucket: 'nobamusicwebsite.appspot.com',
  messagingSenderId: '546824036244',
  appId: '1:546824036244:web:68e4febd93476e1dbdb981',
  measurementId: 'G-C098FX6QF1',
}

firebase.initializeApp(firebaseConfig)
export const storage = firebase.storage()

export default firebase
