import React from "react";
// import sideImage from '../assets/noba_about.png'

const AboutView = () => {
  return (
    <React.Fragment>
      <div className="lg:px-60 pt-20 px-10 mb-3 flex items-center">
        <h1 className="text-white font-semibold text-3xl mr-3 lg:text-5xl">
          Bio
        </h1>
        <div className="border-t-2 w-24 border-white inline-block"></div>
      </div>
      <div className="relative about-us-image lg:about-us-image-large">
        <p className="lg:px-60 px-10 font-light text-white lg:text-xl">
          Noba is a Neosoul singer from South Africa whose soulful, warm voice
          manifests a rich, genuine expression. Gracefully soulful and sincere,
          Noba’s rich, alluring tone is refreshingly smooth and uplifting.
          <br />
          <br />
          Born in Mpumalanga South Africa, a region described as “a place where
          the sun rises”, the singer was raised in the city of Johannesburg.
          Growing up in South Africa, a country rich in music, she felt
          energised by many of the country's outstanding singers.
          <br />
          <br />
          Captivated by music from an early age, the youngster became interested
          in piano at age of 6, the love of singing came
          naturally to Noba and she frequently sang in church alongside her
          Mother.
          <br />
          <br />
          Throughout high school, she proceeded to enroll in various music
          classes from piano to singing, becoming head of the choir by seventh
          grade. Keen to engage in various musical events, Noba competed in
          “Battle of the high school bands” in which she earned first place for
          vocal performance in 2016.
          <br />
          <br />
          Realising her ambition early, she dedicated herself to pursuing a
          career in music enrolling in college to study music and expanding her
          musical knowledge further.
          <br />
          <br />
          With a warm affection for a variety of music genres — Jazz, Afro-pop,
          Gospel and R&B — and inspired by artists such as Alicia Keys,
          Mafikizolo, Beyonce and Freshlyground, the young artist is committed
          to offering the best of herself as a unique Neosoul singer.
          <br />
          <br />
          Beginning her song-writing journey several years ago, she writes
          meaningful songs around love, social justice and self-development,
          narrating stories that are compassionate and authentic her emotive
          tone expresses a sincerity that people can relate to.
          <br />
          <br />
          Performing live is something this gifted singer feels passionate
          about, using her voice as a channel of expression she has performed at
          various live events including The Bowery Ballroom, S.O.B’s, and Gold
          Sounds Bar, and even had the pleasure of performing at Madison Square
          Garden.
          <br />
          <br />
          Expressing stories of hope, this exciting new artist is currently
          working on several projects as well as growing her song collection,
          with a side that is immensely compassionate Noba’s ambition is to
          continue to write songs which uplift with a message of hope.
        </p>
      </div>
    </React.Fragment>
  );
};

export default AboutView;
