import React from "react";
import { Link } from "@reach/router";

import { AiOutlineClose } from "react-icons/ai";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

const NavigationMenu = ({ setToggle }) => {
  return (
    <div className="bg-black h-screen w-full absolute z-50">
      {/* Hamburger Icon */}
      <div className="flex justify-end p-3">
        <div onClick={() => setToggle(false)}>
          <AiOutlineClose className="text-white h-14 w-14 cursor-pointer hover:text-gray-400" />
        </div>
      </div>

      {/* List menu */}
      <div className="flex flex-col items-center justify-center mt-40 space-y-8">
        <div className="text-white flex flex-col space-y-4">
          <Link
            onClick={() => setToggle(false)}
            className="hover:text-gray-400"
            to="/"
          >
            Home
          </Link>
          <Link
            onClick={() => setToggle(false)}
            className="hover:text-gray-400"
            to="/about"
          >
            Bio
          </Link>
          <Link
            onClick={() => setToggle(false)}
            className="hover:text-gray-400"
            to="/videos"
          >
            Videos
          </Link>

          <Link
            onClick={() => setToggle(false)}
            className="hover:text-gray-400"
            to="/gallery"
          >
            Gallery
          </Link>
          <Link
            onClick={() => setToggle(false)}
            className="hover:text-gray-400"
            to="/music"
          >
            Music
          </Link>
        </div>
        <div className="text-white flex space-x-4 ">
          <a href="mailto:noba.musicusa@gmail.com">
            <MdEmail className="h-6 w-6 hover:text-gray-400" />
          </a>
          <a
            href="https://twitter.com/NOBA_MUSIC"
            target="_blank"
            rel="noreferrer"
          >
            <FaTwitter className="h-6 w-6 hover:text-gray-400" />
          </a>
          <a
            href="https://www.youtube.com/channel/UC2mXpubhUyYcNaMV5HjIoqQ"
            target="_blank"
            rel="noreferrer"
          >
            <FaYoutube className="h-6 w-6 hover:text-gray-400" />
          </a>
          <a
            href="https://www.instagram.com/noba_music/?hl=en "
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram className="h-6 w-6 hover:text-gray-400" />
          </a>
          <a
            href="https://www.facebook.com/NOBA-MUSIC-106314760774585"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebookF className="h-6 w-6 hover:text-gray-400" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default NavigationMenu;
